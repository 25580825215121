import { SquidWidget } from "@0xsquid/widget";
import BaseModal from "components/Modal/BaseModal";
import "./index.css";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
const SquidRouterWidget = ({ setIsVisible }: any) => {
  return (
    <>
      <Stack
        component="a"
        sx={{ position: "relative", height: "100%" }}
        alignItems="flex-end"
        justifyContent={"center"}
      >
        <Box
          onClick={() => {
            setIsVisible(true);
          }}
          className="pulse-button"
          sx={{
            fontSize: "12px",
            position: "absolute",
            display: "flex",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "center",
            background: "#b500ef",
            fontWeight: 600,
            top: "3px",
            left: "49px",
            height: "20px",
            width: "37px",
            paddingY: 0.4,
            paddingX: 1,
            color: "white",
          }}
        >
          NEW
        </Box>

        <span
          id="bridge-nav-link"
          onClick={(e) => {
            e.preventDefault();
            setIsVisible(true);
          }}
        >
          Bridge
        </span>
      </Stack>
    </>
  );
};

export default SquidRouterWidget;
