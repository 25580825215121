import React from "react";
import { Trans } from "@lingui/macro";
import SEO from "components/Common/SEO";

import Footer from "components/Footer/Footer";
import { getPageTitle } from "lib/legacy";

import "./Ecosystem.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ARBITRUM, AVALANCHE, BASE_MAINNET, BASE_TESTNET } from "config/chains";
import { t } from "@lingui/macro";
import { getIcon } from "config/icons";

const NETWORK_ICONS = {
  [ARBITRUM]: getIcon(ARBITRUM, "network"),
  [AVALANCHE]: getIcon(AVALANCHE, "network"),
  [BASE_MAINNET]: getIcon(BASE_MAINNET, "network"),
  [BASE_TESTNET]: getIcon(BASE_TESTNET, "network"),
};

const NETWORK_ICON_ALTS = {
  [ARBITRUM]: "Arbitrum Icon",
  [AVALANCHE]: "Avalanche Icon",
  [BASE_MAINNET]: "Base Icon",
  [BASE_TESTNET]: "Base Icon",
};

export default function Ecosystem() {
  // TODO: add links
  const gmxPages = [
    {
      title: "Nether Lords NFT",
      link: "https://lords.nether.fi",
      linkLabel: "lords.nether.fi",
      about: `Nether Lords NFT Collection coming soon on Base!`,
      chainIds: [BASE_MAINNET],
    },
    {
      title: "NFI Stats",
      link: "https://stats.nether.fi",
      linkLabel: "stats.nether.fi",
      about: t`NFI Stats Page`,
      chainIds: [BASE_MAINNET],
    },
    // {
    //   title: "NFI Proposals",
    //   link: "https://snapshot.org/#/gmx.eth",
    //   linkLabel: "snapshot.org",
    //   about: t`NFI Proposals Voting page`,
    //   chainIds: [BASE_TESTNET],
    // },
    // {
    //   title: "Nether Labs",
    //   link: "https://t.me/netherlabs",
    //   linkLabel: "t.me",
    //   about: t`Nether Announcements and Updates`,
    //   chainIds: [BASE_TESTNET],
    // },
  ];

  return (
    <SEO title={getPageTitle("Ecosystem Projects")}>
      <div className="default-container page-layout">
        <div>
          <div className="section-title-block">
            <div className="section-title-icon" />
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>NFI Pages</Trans>
              </div>
              <div className="Page-description">
                <Trans>NFI ecosystem pages.</Trans>
              </div>
            </div>
          </div>
          <div className="DashboardV2-projects">
            {gmxPages.map((item) => {
              const linkLabel = item.linkLabel ? item.linkLabel : item.link;
              return (
                <div className="App-card" key={item.title}>
                  <div className="App-card-title">
                    {item.title}
                    <div className="App-card-title-icon">
                      {item.chainIds.map((network) => (
                        <img width="16" key={network} src={NETWORK_ICONS[network]} alt={NETWORK_ICON_ALTS[network]} />
                      ))}
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Link</Trans>
                      </div>
                      <div>
                        <ExternalLink href={item.link}>{linkLabel}</ExternalLink>
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>About</Trans>
                      </div>
                      <div>{item.about}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
