import React, { useCallback } from "react";
import Footer from "components/Footer/Footer";
import "./BuyGMX.css";
import { useWeb3React } from "@web3-react/core";
import { Trans, t } from "@lingui/macro";
import Button from "components/Button/Button";
import { ARBITRUM, AVALANCHE, BASE_TESTNET, getChainName, getConstant } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import Card from "components/Common/Card";
import { importImage } from "lib/legacy";
import uniswapArbitrumIcon from "img/ic_uni_arbitrum.svg";
import equalizerIcon from "img/scale-svg.svg";
import uniswapIcon from "img/ic_uni_40.svg";
import odosIcon from "img/odos-avatar.png";
import {
  BUY_NATIVE_TOKENS,
  CENTRALISED_EXCHANGES,
  DECENTRALISED_AGGRIGATORS,
  EXTERNAL_LINKS,
  FIAT_GATEWAYS,
  GMX_FROM_ANY_NETWORKS,
  TRANSFER_EXCHANGES,
} from "./constants";

export default function BuyGMX() {
  const { chainId } = useChainId();
  const isArbitrum = chainId === ARBITRUM;
  const { active } = useWeb3React();
  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const externalLinks = EXTERNAL_LINKS[chainId];

  const onNetworkSelect = useCallback(
    (value) => {
      if (value === chainId) {
        return;
      }
      return switchNetwork(value, active);
    },
    [chainId, active]
  );

  return (
    <div className="BuyGMXGLP default-container page-layout">
      <div className="BuyGMXGLP-container">
        {/* <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Buy NFI on {getChainName(chainId)}</Trans>
            </div>
            <div className="Page-description">
              <Trans>Choose to buy from decentralized exchanges.</Trans>
              <br />
                        {/* <Trans>
                          To purchase NFI on the Base blockchain, please{" "}
                          <span onClick={() => onNetworkSelect(BASE_TESTNET)}>change your network</span>.
                        </Trans> 
            </div>
          </div>
        </div>
        <div className="cards-row">
          <DecentralisedExchanges chainId={chainId} externalLinks={externalLinks} />
                      {/* <CentralisedExchanges chainId={chainId} /> 
        </div> */}

        {/* {isArbitrum ? ( */}
        <div className="section-title-block mt-top">
          <div className="section-title-content">
            <div className="Page-title">
              <Trans>Buy NFI or Transfer ETH to Base</Trans>
            </div>
            {/* <div className="Page-description">
              <Trans>Buy ETH directly to Base or transfer it there.</Trans>
            </div> */}
          </div>
        </div>
        {/* ) : (
          <div className="section-title-block mt-top">
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Buy or Transfer AVAX to Avalanche</Trans>
              </div>
              <div className="Page-description">
                <Trans>Buy AVAX directly to Avalanche or transfer it there.</Trans>
              </div>
            </div>
          </div>
        )} */}

        <div className="cards-row">
          <Card title={t`Buy NFI`}>
            <div className="App-card-content">
              <div className="BuyGMXGLP-description">
                <Trans>To get the best rates for NFI tokens, we recommend using Odos Swap Aggregator:</Trans>
              </div>
              <div className="buttons-group">
                <Button variant="clear" imgInfo={{ src: odosIcon, alt: "ODOS" }} to={externalLinks.buyGmx.odos} newTab>
                  Odos
                </Button>
              </div>
            </div>
            <div className="App-card-content" style={{ marginTop: "1rem" }}>
              <div className="BuyGMXGLP-description">
                <Trans>Alternatively, you can buy NFI directly on these exchanges:</Trans>
              </div>
              <div className="buttons-group">
                <Button
                  variant="clear"
                  imgInfo={{ src: equalizerIcon, alt: "Equalizer" }}
                  to={externalLinks.buyGmx.equalizer}
                  newTab
                >
                  Equalizer
                </Button>
                <Button
                  variant="clear"
                  imgInfo={{ src: uniswapIcon, alt: "Uniswap" }}
                  to={externalLinks.buyGmx.uniswap}
                  newTab
                >
                  Uniswap
                </Button>
              </div>
            </div>
          </Card>
          <Card title={t`Transfer ${nativeTokenSymbol}`}>
            <div className="App-card-content">
              <div className="BuyGMXGLP-description">
                <Trans>You can transfer ETH from other networks to Base using any of the below options:</Trans>
              </div>

              <div className="buttons-group">
                {TRANSFER_EXCHANGES.filter((e) => chainId in e.links).map((exchange) => {
                  const icon = importImage(exchange.icon) || "";
                  const link = exchange.links[chainId];
                  return (
                    <Button
                      variant="clear"
                      key={exchange.name}
                      to={link}
                      imgInfo={{ src: icon, alt: exchange.name }}
                      newTab
                    >
                      {exchange.name}
                    </Button>
                  );
                })}
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function DecentralisedExchanges({ chainId, externalLinks }) {
  const isArbitrum = chainId === ARBITRUM;
  return (
    <Card title={t`Buy NFI from a Decentralized Exchange`}>
      <div className="App-card-content">
        {/* {isArbitrum ? ( */}
        <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <Trans>Buy NFI from Uniswap (make sure to select Base):</Trans>
          </div>
          <div className="buttons-group col-1">
            <Button
              variant="clear"
              imgInfo={{ src: uniswapArbitrumIcon, alt: "Uniswap" }}
              to={externalLinks.buyGmx.uniswap}
              newTab
            >
              Uniswap
            </Button>
          </div>
        </div>
        {/* ) : (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <Trans>Buy GMX from Traderjoe:</Trans>
            </div>
            <div className="buttons-group col-1">
              <Button
                variant="clear"
                to={externalLinks.buyGmx.traderjoe}
                imgInfo={{ src: traderjoeIcon, alt: "Traderjoe" }}
                newTab
              >
                TraderJoe
              </Button>
            </div>
          </div>
        )} */}
        {/* <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <Trans>Buy NFI using Decentralized Exchange Aggregators:</Trans>
          </div>
          <div className="buttons-group">
            {DECENTRALISED_AGGRIGATORS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links[chainId];
              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <Trans>Buy NFI using any token from any network:</Trans>
          </div>
          <div className="buttons-group">
            {GMX_FROM_ANY_NETWORKS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links[chainId];
              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div> */}
        {/* {isArbitrum && (
          <div className="exchange-info-group">
            <div className="BuyGMXGLP-description">
              <Trans>GMX bonds can be bought on Bond Protocol with a discount and a small vesting period:</Trans>
            </div>
            <div className="buttons-group col-1">
              <Button
                variant="clear"
                to={"https://app.bondprotocol.finance/#/issuers/GMX"}
                imgInfo={{ src: bondProtocolIcon, alt: "Bond Protocol" }}
                newTab
              >
                Bond Protocol
              </Button>
            </div>
          </div>
        )} */}
      </div>
    </Card>
  );
}

function CentralisedExchanges({ chainId }) {
  return (
    <Card title={t`Buy NFI from centralized services`}>
      <div className="App-card-content">
        <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <Trans>Buy NFI from centralized exchanges:</Trans>
          </div>
          {/* TODO: add NFI */}
          <div className="buttons-group">
            {CENTRALISED_EXCHANGES.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              const link = exchange.links[chainId];
              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>

        <div className="exchange-info-group">
          <div className="BuyGMXGLP-description">
            <Trans>Buy NFI using FIAT gateways:</Trans>
          </div>
          {/* TODO: add NFI */}
          <div className="buttons-group col-2">
            {FIAT_GATEWAYS.filter((e) => chainId in e.links).map((exchange) => {
              const icon = importImage(exchange.icon) || "";
              let link = exchange.links[chainId];

              return (
                <Button
                  variant="clear"
                  key={exchange.name}
                  to={link}
                  imgInfo={{ src: icon, alt: exchange.name }}
                  newTab
                >
                  {exchange.name}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
