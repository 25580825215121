import "./Modal.css";
import React, { useRef, useEffect } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";

export default function BaseModal(props) {
  const { isVisible, setIsVisible, className, zIndex, onAfterOpen } = props;

  const modalRef = useRef(null);

  useEffect(() => {
    function close(e) {
      if (e.keyCode === 27 && setIsVisible) {
        setIsVisible(false);
      }
    }
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [setIsVisible]);

  useEffect(() => {
    if (typeof onAfterOpen === "function") onAfterOpen();
  }, [onAfterOpen]);

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <AnimatePresence>
      <motion.div
        className={cx("Modal", className)}
        style={{ zIndex, display: isVisible ? "flex" : "none" }}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={fadeVariants}
        transition={{ duration: 0.2 }}
      >
        <div
          className="Modal-backdrop"
          style={{
            overflow: isVisible ? "hidden" : "visible",
            position: "fixed",
          }}
          onClick={() => setIsVisible(false)}
        ></div>
        <div
          className="Modal-content"
          style={{ width: "42rem", fontSize: "18px !important", background: "rgb(16,12,29)" }}
          ref={modalRef}
        >
          {props.children}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
